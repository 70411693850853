<template>
  <div style="margin-top: 10px;">
    <el-descriptions border :column="1" style="width: 600px">
      <el-descriptions-item label="设备IP">{{ device.ip }}</el-descriptions-item>
      <el-descriptions-item label="MAC">{{ device.hwaddr }}</el-descriptions-item>
      <el-descriptions-item label="CPU温度">{{ device.cputemp }}</el-descriptions-item>
      <el-descriptions-item label="CPU占用">{{ device.cpuload }}</el-descriptions-item>
      <el-descriptions-item label="内存总量(M)">{{ device.memtotal }}</el-descriptions-item>
      <el-descriptions-item label="内存占用(M)">{{ device.memuse }}</el-descriptions-item>
      <el-descriptions-item label="硬盘总量(M)">{{ device.mmctotal }}</el-descriptions-item>
      <el-descriptions-item label="硬盘占用(M)">{{ device.mmcuse }}</el-descriptions-item>
      <el-descriptions-item label="固件版本">{{ device.version }}</el-descriptions-item>
      <el-descriptions-item label="设备ID">{{ device.deviceId }}</el-descriptions-item>
      <el-descriptions-item label="设备类型">{{ device.model }}</el-descriptions-item>
    </el-descriptions>
    <div style="margin-top: 10px">
      <el-button type="warning">设备重启</el-button>
      <el-button type="danger">安全关机</el-button>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  name: 'DeviceInfo',
  components: {

  },
  data: function() {
    return {
      // host: '',
      host: 'http://192.168.11.100:8099',
      device_list: [
        1, 2, 3, 4, 5
      ],
      running_seqs: [],
      device: {
        ip: '',
        hwaddr: '',
        cputemp: '',
        cpuload: '',
        memtotal: '',
        memuse: '',
        mmctotal: '',
        mmcuse: '',
        version: '',
        deviceId: '',
        model: ''
      }
    }
  },
  created() {
    console.log('mounted')
    this.get_device_info()
    setInterval(() => {
      this.get_device_info()
    }, 5000)
  },
  methods: {
    get_device_info() {
      request({
        url: `${this.host}/device`,
        method: 'get'
      }).then(response => {
        console.log(response)
        this.device = response
      })
    }
  }
}
</script>
<style scoped>
.container {
  padding: 5px;
}

.tools {
  margin: 5px;
}

.item {
  padding: 5px;
}
</style>
