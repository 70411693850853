<template>
  <div>
    <div class="container" style="display: flex;">
      <container-item v-for="(item) in container_list" :key="item" :data="item" :link="{host, seq: item}" :running-seqs="running_seqs" />
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import ContainerItem from '@/components/ContainerItem.vue'

export default {
  name: 'ContainerList',
  components: { ContainerItem },
  data: function() {
    return {
      // host: '',
      host: 'http://192.168.11.100:8099',
      device_list: [
        1, 2, 3, 4, 5
      ],
      running_seqs: [],
      container_list: []
    }
  },
  created() {
    console.log('mounted')
    this.get_running_container()
    this.get_running_task()
    setInterval(() => {
      this.get_running_task()
    }, 5000)
  },
  methods: {
    get_running_container() {
      request({
        url: `${this.host}/containers`,
        method: 'get'
      }).then(response => {
        console.log(response)
        this.container_list = response
      })
    },
    get_running_task() {
      request({
        url: `${this.host}/running`,
        method: 'get'
      }).then(response => {
        console.log(response)
        // task_d8_s4_t3717
        for (let i = 0; i < response.length; i++) {
          // 更新到container_list中
          const seq = response[i].split('_s')[1].split('_t')[0]
          const task_id = response[i].split('_t')[1]
          const index = this.container_list.findIndex(item => item.labels.idx === seq)
          console.log(index)
          if (index !== -1) {
            this.container_list[index].running = true
            this.container_list[index].running_task = task_id
            this.container_list[index].running_task_process = response[i]
          }
        }
        console.log(this.container_list)

        // this.running_seqs = response.map(item => {
        //   return item.split('_s')[1].split('_t')[0]
        // })
        // console.log(response)
        // console.log(this.running_seqs)
      })
    }
  }
}
</script>
<style scoped>
.container {
  padding: 5px;
}

.tools {
  margin: 5px;
}

.item {
  padding: 5px;
}
</style>
