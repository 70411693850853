<template>
  <div style="margin: 5px;">
    <el-dialog title="设备控制" :visible.sync="dialogVisible" width="453px" custom-class="no-padding-dialog">
      <iframe style="border: 0; height: 720px; width: 453px;" :src="scrcpy_link" />
    </el-dialog>

    <el-image style="width: 180px;height: 300px;border: 1px solid " :src="image">
      <img slot="placeholder" :src="image" class="el-image__placeholder" alt="">
    </el-image>
    <div style="display: flex; justify-content: space-between; width: 20%; margin-left: 10px;">
      <el-tag style="margin-right: 10px">{{ data.labels.idx }}</el-tag>
      <el-button v-if="data.running" type="primary" size="mini" @click="test">运行中</el-button>
      <el-button v-if="data.running" type="primary" size="mini" @click="open_log">日志</el-button>
      <el-button v-show="false" type="primary" size="mini" @click="open_scrcpy">打开</el-button>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'ContainerItem',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    link: {
      type: Object,
      default: () => ({})
    },
    runningSeqs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      image: '',
      scrcpy_link: '',
      dialogVisible: false,
      showViewer: false

    }
  },
  created() {
    console.log(this)
    this.refreshImageUrl()
    setInterval(this.refreshImageUrl, 1500)
    console.log(this.runningSeqs)
  },
  methods: {
    refreshImageUrl() {
      this.showViewer = true
      // this.image = `${this.link.host}/screen?ip=127.0.0.1&seq=${this.link.seq}&t=${new Date().getTime()}`
      // this.image = `${this.link.host}/screen?ip=127.0.0.1&seq=${this.link.seq}&t=${new Date().getTime()}`
      this.image = `${this.link.host}/screen?ip=127.0.0.1&seq=${this.data.labels.idx}&t=${new Date().getTime()}`
      this.$nextTick(() => {
        this.showViewer = false
      })
    },
    test() {
      console.log(this.data.running_task_process)
      this.$confirm('确定要停止吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request({
          url: `${this.link.host}/kill?name=${this.data.running_task_process}`,
          method: 'get'
        }).then(response => {
          console.log(response)
          this.data.running = false
          this.$message({
            message: '停止成功',
            type: 'success'
          })
        })
      }).catch(() => {})
    },
    open_log() {
      // 新窗口打开
      window.open(`${this.link.host}/log?task_id=${this.data.running_task}`)
    },
    open_scrcpy() {
      this.$message({
        message: '正在开发...',
        type: 'warning'
      })
      // const seq = this.link.seq
      // const ws_url = `ws://192.168.7.191:8000/?action=proxy-adb&remote=tcp%3A8886&udid=127.0.0.1%3A500${seq}`
      // const encode_ws_url = encodeURIComponent(ws_url)
      // console.log(encode_ws_url)
      // const full_url = `http://192.168.7.191:8000/#!action=stream&udid=127.0.0.1%3A5001&player=mse&ws=${encode_ws_url}`
      // console.log(full_url)
      // this.scrcpy_link = full_url
      // this.dialogVisible = true
    }
  }
}
</script>

<style>
.no-padding-dialog .el-dialog__body {
  padding: 0;
}

.no-padding-dialog .el-dialog__header {
    padding: 8px
}
</style>
