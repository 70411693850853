<template>
  <div id="app">
    <el-menu
      :default-active="activeIndex"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">容器列表</el-menu-item>
      <el-menu-item index="2">文件管理</el-menu-item>
      <el-menu-item index="3">设备信息</el-menu-item>
      <el-menu-item index="4">TK云大师自动化 <el-tag type="danger">未激活</el-tag></el-menu-item>
    </el-menu>
    <container-list v-if="activeIndex==='1'" />
    <div v-if="activeIndex==='2'">
      <div style="margin-top: 10px;">
        <el-card shadow="always">
          敬请期待
        </el-card>
      </div>
    </div>
    <device-info v-if="activeIndex==='3'" />
    <div v-if="activeIndex==='4'">
      <div style="width: 800px;margin-top: 10px;">
        <el-card shadow="always">
          欢迎使用TK云大师！这款创新工具支持一键新机、账号环境备份、智能养号、资料修改和作品发布等多项功能，旨在为用户提供安全、高效的账号管理体验。无论您是个人创作者还是团队运营，我们的系统都能助您轻松优化 TikTok 账号，提升内容传播效果。立即体验，开启您的 TikTok 创作之旅！ <br>开通,请联系:XXXX
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>

import request from '@/utils/request'
import ContainerList from '@/components/ContainerList.vue'
import DeviceInfo from '@/components/DeviceInfo.vue'

export default {
  name: 'App',
  components: {
    DeviceInfo,
    ContainerList
  },
  data() {
    return {
      activeIndex: '1'
    }
  },
  created() {
    this.setTabFromHash()
    window.onhashchange = () => {
      this.setTabFromHash()
    }
  },
  methods: {
    test() {
      request({
        url: 'api/test',
        method: 'get'
      }).then(res => {
        this.$message({
          message: res,
          type: 'success'
        })
      })
    },
    setTabFromHash() {
      const hash = window.location.hash.replace('#', '')
      if (hash) {
        this.activeIndex = hash
      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
      window.location.hash = key
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
